import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import { motion } from "framer-motion";
import { DayAndNightToggle } from "./DayAndNightToggle";
import pako from "pako";
import Papa from "papaparse";
import Dialog from "rc-dialog";
import Drawer from "react-modern-drawer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./ContentBlock/PrivacyPolicy";
import SupportPage from "./ContentBlock/SupportPage";
import ExternalResourcesPage from "./ContentBlock/ExternalResourcesPage";
import Terms from "./ContentBlock/Terms";
import { FaGooglePlay } from "react-icons/fa";
import { useAdBlockDetector } from "adblock-detect-react";
// import AdBlockMessage from "./AdBlockDetector";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
//import styles 👇
import "react-modern-drawer/dist/index.css";
import "rc-dialog/assets/index.css";
import { AppContext } from "./Context/AppContext";

const colors = ["#ff0000", "#800080", "#16279f"];

function App() {
  const { isDarkMode, setIsDarkMode } = useContext(AppContext);
  const [buttonText, setButtonText] = useState("GENERATE");
  const [serverDetailsList, setServerDetailsList] = useState([]);
  const [stepsText, setStepsText] = useState("STEP 1");
  const [serverText, setServerText] = useState("");
  const [usernameText, setUsernameText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [regionText, setRegionText] = useState("");
  const [expiryText, setExpiryText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [showPassword, setShowPassword] = useState(false);
  const [currentServer, setCurrentServer] = useState(null);
  const [currentServerInfo, setCurrentServerInfo] = useState(null);

  const [toggleCurrentServerInfo, setToggleCurrentServerInfo] = useState(false);
  const [toggleCopyShareContainer, setToggleCopyShareContainer] =
    useState(false);
  const [toggleShareDrawer, setToggleShareDrawer] = useState(false);

  const [showSkipButton, setShowSkipButton] = useState(false);
  const [showFullScreenAd, setShowFullScreenAd] = useState(false);

  // Function to load AdSense ad
  const loadAd = () => {
    const adContainer = document.getElementById("ad-container");
    const fullScreenAd = document.getElementById("full-screen-ad");

    // Clear existing ad content
    if (adContainer) {
      adContainer.innerHTML = "";
    }

    // Create <ins> element for AdSense ad
    const insElement = document.createElement("ins");
    insElement.className = "adsbygoogle";
    insElement.setAttribute("style", "display:block");
    insElement.setAttribute("data-ad-client", "ca-pub-9700554883020818"); // Your Ad Client ID
    insElement.setAttribute("data-ad-slot", "7678422922");
    insElement.setAttribute("data-ad-format", "auto");
    insElement.setAttribute("data-full-width-responsive", "true");
    // Remove test mode (for production)
    insElement.removeAttribute("data-adtest");

    // Append <ins> element to ad container
    if (adContainer) {
      adContainer.appendChild(insElement);
    }

    // Dynamically load the AdSense script
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9700554883020818";
    script.async = true;
    script.onload = () => {
      // Initialize the ad after the script loads
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    };

    // Append the script to the ad container (or body, for global loading)
    if (adContainer) {
      adContainer.appendChild(script);
    } else {
      document.body.appendChild(script); // Fallback to appending directly to the body
    }

    // Show the full-screen ad and skip button after 5 seconds
    setShowFullScreenAd(true);
    setTimeout(() => {
      setShowSkipButton(true);
    }, 1000);
  };

  // Ensure ad content is removed and adsbygoogle array is reset when ad is skipped
  const handleSkipAd = () => {
    setShowFullScreenAd(false);
    setShowSkipButton(false);
    const adContainer = document.getElementById("ad-container");

    // Cleanup the ad container when skipping the ad
    if (adContainer) {
      adContainer.innerHTML = "";
    }

    // Optionally, reset or remove other ad-related resources to prevent overlap in the future
    (window.adsbygoogle = window.adsbygoogle || []).length = 0;
  };

  const fetchGzipFile = async () => {
    setIsLoading(true);
    setButtonText("WAITING");

    // const urls = [
    //   "https://cloud.appwrite.io/v1/storage/buckets/67b32a6600026ac1a965/files/67b32a83000217cc2e69/view?project=67b32a5100047fb91829&mode=admin",
    //   "https://pnktnulxxfoipfbgwmjm.supabase.co/storage/v1/object/public/SERVERS//app_servers.csv.gz"
    // ];

    const urls = [
      "https://pub-ee07580cc5074618b8b8cb5346548803.r2.dev/website-db.csv.gz"
    ]
    // const urls = [
    //   "https://pub-aca5e18e876e460191f73beeec393266.r2.dev/xtream-db.csv.gz"
    // ]

    for (let i = 0; i < urls.length; i++) {
      try {
        const response = await fetch(urls[i]);
        if (!response.ok) {
          console.log(response)
          console.warn(`HTTP error from ${urls[i]}! status: ${response.status}`);
          continue; // Try next URL
        }

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();

        // Decompress the gzipped file
        const decompressed = pako.inflate(new Uint8Array(arrayBuffer), { to: "string" });

        // Parse CSV
        const results = await new Promise((resolve, reject) => {
          Papa.parse(decompressed, {
            header: false,
            complete: (parsedResults) => resolve(parsedResults),
            error: (err) => reject(new Error(`Failed to parse CSV: ${err.message}`))
          });
        });

        console.log(results)
        // Format data
        const formattedData = results.data.map((item) => ({
          url: item[0],
          username: item[1],
          password: item[2],
          region: item[4],
          expiry: item[3]
        }));

        setServerDetailsList(formattedData);
        setButtonText(results.data.length > 0 ? "GENERATE" : "RETRY");
        setIsLoading(false);
        return; // Exit if successful
      } catch (error) {
        console.log(error)
        console.error(`Error fetching from ${urls[i]}: ${error.message}`);
      }
    }

    // If both URLs fail
    setError("Failed to download file from both sources.");
    setButtonText("RETRY");
    setIsLoading(false);
  };





  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  let isCalled = false;
  useEffect(() => {
    if (!isCalled) {
      fetchGzipFile();
      isCalled = true;
    }
  }, []);

  const playAudio = (filename) => {
    const audio = new Audio(`${process.env.PUBLIC_URL}/${filename}`); // Path to the audio file in the public folder
    audio.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  };
  let isCheckingTextCompleted = false;

  const showCheckingText = async () => {
    setButtonText("CHECKING (5)");
    for (let i = 4; i > 0; i--) {
      // Start from 4 for a total of 5 seconds
      await sleep(1000); // Wait for 1 second
      setButtonText(`CHECKING (${i})`);
    }
    isCheckingTextCompleted = true;
  };

  const onButtonSubmit = async () => {
    setIsLoading(true);
    setError(null);

    if (buttonText === "GENERATE" || buttonText === "GENERATE ANOTHER") {
      setShowPassword(false);
      setToggleCopyShareContainer(false);
      setButtonText("GENERATING");
      await sleep(3000);

      const randomIndex = Math.floor(Math.random() * serverDetailsList.length);
      let server_ = serverDetailsList[randomIndex];

      setCurrentServer(server_);
      console.log(server_);

      setServerText(""); // Initially blank
      setUsernameText(""); // Initially blank
      setPasswordText(""); // Initially blank
      setRegionText(""); // Initially blank
      setExpiryText(""); // Initially blank

      setStepsText("STEP 1");
      setButtonText("NEXT");
      loadAd();
    }
    else if (stepsText === "STEP 1" && buttonText === "NEXT") {
      setServerText(currentServer.url);
      setStepsText("STEP 2");
    }
    else if (stepsText === "STEP 2") {
      setUsernameText(currentServer.username);
      setStepsText("STEP 3");
    }
    else if (stepsText === "STEP 3") {
      setPasswordText(currentServer.password);
      setStepsText("STEP 4");
    }
    else if (stepsText === "STEP 4") {
      setRegionText(currentServer.region);
      setStepsText("STEP 5");
    }
    else if (stepsText === "STEP 5") {
      setExpiryText(currentServer.expiry);
      setStepsText("COMPLETED");
      setButtonText("CHECK STATUS");
    }
    else if (buttonText === "CHECK STATUS") {
      const proxy = "https://api.cardpro.co.in/proxy?url=";
      const url =
        proxy +
        encodeURIComponent(
          `${currentServer.url}/player_api.php?username=${currentServer.username}&password=${currentServer.password}&type=m3u_plus&output=ts`
        );

      try {
        showCheckingText();
        const response = await fetch(url);
        const data = await response.json();
        loadAd();

        const checkInterval = setInterval(() => {
          if (isCheckingTextCompleted) {
            clearInterval(checkInterval);
            if (!response.ok) {
              setError("UNABLE TO SHOW SERVER DETAILS");
              setStepsText("VIEW PASSWORD");
              setButtonText("VIEW PASSWORD");
            }

            if (data && data.user_info) {
              const userInfo = data.user_info;
              const serverInfo = data.server_info;

              const createdAtDate = new Date(userInfo.created_at * 1000);
              const expDate = new Date(userInfo.exp_date * 1000);

              const formattedCreatedAt = createdAtDate.toLocaleString();
              const formattedExpDate = expDate.toLocaleString();

              let serverInfo_ = {
                username: userInfo.username,
                password: userInfo.password,
                status: userInfo.status,
                isTrial: userInfo.is_trial === "0" ? "No" : "Yes",
                createdAt: formattedCreatedAt,
                expDate: formattedExpDate,
                url: serverInfo.url,
                port: serverInfo.port,
                timezone: serverInfo.timezone,
                timeNow: serverInfo.time_now,
                maxConnections: userInfo.max_connections,
              };
              setCurrentServerInfo(serverInfo_);
              setToggleCurrentServerInfo(true);
              setStepsText("VIEW PASSWORD");
              setButtonText("VIEW PASSWORD");
              playAudio("success.mp3");
            } else {
              playAudio("failed.mp3");
              setError("THIS SERVER IS NOT WORKING. GENERATE ANOTHER");
              setStepsText("STEP 1");
              setButtonText("GENERATE");
            }
          }
        }, 300);
      } catch (error) {
        const checkInterval = setInterval(() => {
          if (isCheckingTextCompleted) {
            clearInterval(checkInterval);
            playAudio("failed.mp3");
            setError("THIS SERVER IS NOT WORKING. GENERATE ANOTHER");
            setStepsText("STEP 1");
            setButtonText("GENERATE");
          }
        }, 300);
      }
    }
    else if (buttonText === "VIEW PASSWORD") {
      setButtonText("LOADING");
      await sleep(3000);
      setShowPassword(true);
      setButtonText("GENERATE ANOTHER");
      setToggleCopyShareContainer(true);
      loadAd();
    }
    else if (buttonText === "RETRY") {
      await fetchGzipFile();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    console.log(stepsText)
    setShowFullScreenAd(true);
    setTimeout(() => {
      setShowSkipButton(true);
    }, 1000);
  }, [stepsText])

  return (
    <>
    {/* <AdBlockMessage /> */}

    {showFullScreenAd && (
        <div
          id="full-screen-ad"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden", // Ensures the content stays inside the viewport
          }}
        >
          <div
            id="ad-container"
            style={{
              position: "relative",
              backgroundColor: "#fff", // Ad container background
              padding: "20px",
              borderRadius: "8px", // Rounded corners for a cleaner look
              width: "80%", // Adjust the size of the ad container
              maxWidth: "600px", // Set a maximum width for better responsiveness
            }}
          >
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9700554883020818"
              crossorigin="anonymous"
            ></script>

            <ins
              class="adsbygoogle"
              style={{
                display: "block",
                minHeight: "100vh",
                minWidth: "100vw",
              }}
              data-ad-client="ca-pub-9700554883020818"
              data-ad-slot="7678422922"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({ });</script>
            {/* Skip Ad Button */}
            {showSkipButton && (
              <button
                onClick={handleSkipAd}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "red",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  cursor: "pointer",
                  borderRadius: "5px", // Rounded corners for button
                  zIndex: 10,
                }}
              >
                Skip Ad
              </button>
            )}
            {/* Add your ad content here */}
            <div id="ad-content">{/* Your ad content goes here */}</div>
          </div>
        </div>
      )}

      {/* Button to trigger Ad loading */}
      {/* <button onClick={loadAd}>Load Ad</button> */}

      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/external" element={<ExternalResourcesPage />} />
        <Route path="/external" element={<ExternalResourcesPage />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>

      <div>
        <div className={isDarkMode ? "app dark-mode" : "app"}>
          <div className="container">
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9700554883020818"
              crossorigin="anonymous"
            ></script>

            <ins
              class="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-9700554883020818"
              data-ad-slot="7678422922"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({ });</script>

            <motion.div
              initial={{ color: colors[0] }}
              animate={{
                color: colors, // Cycle through colors
              }}
              transition={{
                duration: 1, // Total duration for one full cycle
                ease: "linear",
                repeat: Infinity,
              }}
              style={{ fontSize: "14px", fontWeight: 700 }}
            >
              GET YOUR SERVER IN 3 STEPS
            </motion.div>

            <div className="input-container">
              <div className="input-group">
                <label htmlFor="server">Server:</label>
                <input
                  type="text"
                  id="server"
                  value={stepsText ? serverText : ""}
                  readOnly
                  placeholder="Server"
                />
              </div>

              <div className="input-group">
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  value={
                    ["STEP 3", "STEP 4", "STEP 5", "COMPLETED", "VIEW PASSWORD"].includes(stepsText)
                      ? usernameText
                      : ""
                  }
                  readOnly
                  placeholder="Username"
                />
              </div>

              <div className="input-group">
                <label htmlFor="password">Password:</label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={
                    ["STEP 4", "STEP 5", "COMPLETED", "VIEW PASSWORD"].includes(stepsText)
                      ? passwordText
                      : ""
                  }
                  readOnly
                  placeholder="Password"
                />
              </div>

              <div className="input-group">
                <label htmlFor="expiryText">Expiration:</label>
                <input
                  type="text"
                  id="expiryText"
                  value={["STEP 4", "COMPLETED", "VIEW PASSWORD"].includes(stepsText) ? expiryText : ""}
                  readOnly
                  placeholder="Expiry"
                />
              </div>

              <div className="input-group">
                <label htmlFor="region">Region/City:</label>
                <input
                  type="text"
                  id="region"
                  value={["STEP 5", "COMPLETED", "VIEW PASSWORD"].includes(stepsText) ? regionText : ""}
                  readOnly
                  placeholder="Region"
                />
              </div>
            </div>



            <motion.div
              initial={{ color: colors[0] }}
              animate={{
                color: colors, // Cycle through colors
              }}
              transition={{
                duration: 1, // Total duration for one full cycle
                ease: "linear",
                repeat: Infinity,
              }}
              style={{ fontSize: "14px", fontWeight: 700, marginBottom: 10 }}
            >
              {stepsText}
            </motion.div>
            <span className="error-text">{error}</span>
            <div className="stretch-container">
              <button
                disabled={isLoading}
                className="generate-btn"
                onClick={onButtonSubmit}
              >
                {buttonText}
              </button>
              {toggleCopyShareContainer && (
                <div className="copy-and-share-container">
                  <button
                    onClick={() => {
                      copyToClipboard(
                        serverText + "\n" + usernameText + "\n" + passwordText
                      );
                    }}
                    className="copy-and-share-button copy-and-share-button-left"
                  >
                    COPY
                  </button>
                  <button
                    onClick={() => {
                      setToggleShareDrawer(true);
                    }}
                    className="copy-and-share-button copy-and-share-button-right"
                  >
                    SHARE
                  </button>
                </div>
              )}



              <div className="follow-us-container">
                <p className="follow-us-title">Follow Us</p>

                <div className="follow-links">
                  <a
                    href="https://play.google.com/store/apps/details?id=app.xtream.codegenerator"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="follow-link"
                  >
                    <FaGooglePlay className="play-icon" />
                    <span>Xtreamity plus</span>
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.xtreamity"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="follow-link"
                  >
                    <FaGooglePlay className="play-icon" />
                    <span>Xtreamity</span>
                  </a>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      {currentServerInfo && (
        <Dialog
          className={isDarkMode ? "dialog-dark-mode" : ""}
          title={"Server Details"}
          onClose={() => {
            setToggleCurrentServerInfo(false);
          }}
          visible={toggleCurrentServerInfo}
        >
          <motion.div
            initial={{ color: colors[0] }}
            animate={{
              color: colors, // Cycle through colors
            }}
            transition={{
              duration: 1, // Total duration for one full cycle
              ease: "linear",
              repeat: Infinity,
            }}
            style={{
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "center",
              paddingBottom: 10,
            }}
          >
            CONGRATULATIONS! THE SERVER IS WORKING
          </motion.div>
          <div className={"dialog-server-info-container"}>
            <span className="dialog-server-info-container-span">
              {currentServerInfo.url}
            </span>
            <hr />
            <span className="dialog-server-info-container-span">
              {currentServerInfo.port}
            </span>
            <hr />
            <span className="dialog-server-info-container-span">
              {currentServerInfo.username}
            </span>
            <hr />
            <span className="dialog-server-info-container-span">
              {"*".repeat(currentServerInfo.password.length)}
            </span>
          </div>
          <div className={"dialog-server-info-more-details"}>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Status
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.status}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Created At
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.createdAt}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Expiration Date
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.expDate}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Timezone
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.timezone}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Time Now
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.timeNow}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Max Connections
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.maxConnections}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Is Trial
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.isTrial}
              </span>
            </div>
          </div>
          <motion.div
            initial={{ color: colors[0] }}
            animate={{
              color: colors, // Cycle through colors
            }}
            transition={{
              duration: 1, // Total duration for one full cycle
              ease: "linear",
              repeat: Infinity,
            }}
            style={{ fontSize: "14px", fontWeight: 700, textAlign: "center" }}
          >
            PRESS BACK BUTTON TO VIEW SERVER
          </motion.div>

          <div
            className="dialog-server-info-more-details-back-btn"
            onClick={() => {
              setToggleCurrentServerInfo(false);
            }}
          >
            BACK
          </div>
        </Dialog>
      )}

      <Drawer
        open={toggleShareDrawer}
        onClose={() => {
          setToggleShareDrawer(false);
        }}
        direction="bottom"
        className={
          isDarkMode ? "share-drawer dark-share-drawer" : "share-drawer"
        }
      >
        <div
          className="drawer-share-close-btn"
          onClick={() => {
            setToggleShareDrawer(false);
          }}
        >
          BACK
        </div>
        <h1 className="share-icons-title">SHARE</h1>
        <div className="share-icons-container">
          <div className="share-icons">
            <EmailShareButton
              subject="Sever Details"
              body={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <div className="share-icons">
            <FacebookShareButton url={String(window.location)}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="share-icons">
            <WhatsappShareButton
              url={""}
              title={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div className="share-icons">
            <TelegramShareButton
              url={String(window.location)}
              title={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
          <div className="share-icons">
            <TwitterShareButton
              url={String(window.location)}
              title={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <XIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="share-icons">
            <InstapaperShareButton
              url={String(window.location)}
              title="Sever Details"
              description={
                serverText + "\n" + usernameText + "\n" + passwordText
              }
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>
          <div className="share-icons">
            <PinterestShareButton
              url={serverText + "\n" + usernameText + "\n" + passwordText}
              media={`${String(window.location)}/loadingscreen.png`}
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
          </div>
        </div>
      </Drawer>
      

      
    </>

            
  );

  


}

export default App;
